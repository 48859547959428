import { createApp } from 'vue'
import App from './App.vue'

import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
// 接入了Leancloud
import AV from 'leancloud-storage'

// 请注册leancloud,新建应用，然后替换这个
AV.init({
  appId: '\x42\x77\x4c\x72\x43\x67\x64\x56\x79\x4c\x73\x35\x32\x6d\x4a\x4f\x31\x48\x63\x72\x58\x61\x6b\x49\x2d\x67\x7a\x47\x7a\x6f\x48\x73\x7a', appKey: '\x32\x35\x67\x4e\x77\x7a\x77\x34\x64\x56\x37\x49\x41\x68\x37\x69\x30\x49\x7a\x44\x6e\x59\x76\x56', serverURL: '\x68\x74\x74\x70\x73\x3a\x2f\x2f\x62\x77\x6c\x72\x63\x67\x64\x76\x2e\x6c\x63\x2d\x63\x6e\x2d\x6e\x31\x2d\x73\x68\x61\x72\x65\x64\x2e\x63\x6f\x6d'
})

const app = createApp(App)
app.use(ElementPlus)
app.mount('#app')
